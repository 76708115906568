<template>
    <main style="display: flex;width: 100%;min-height: 400px;background: radial-gradient(circle at 12% 55%,rgba(33,150,243,.15),hsla(0,0%,100%,0) 25%),radial-gradient(circle at 85% 33%,rgba(108,99,255,.175),hsla(0,0%,100%,0) 25%);padding: 20px;">
        <div style="width: 25%; margin-left: 1px;"
             class="relative pt-4 py-3flex h-full flex-col bg-[#fafbfc] draw-list-left flex justify-content-between pb-0"
        >

            <div class="ai-writer__left-panel p-2 overflow-auto">
                <!-- <div class="editor-panel__title-wrap"><h4 class="editor-panel__title">思维导图</h4></div> -->

                <div class="left-panel-setting-block">
                    <div class="left-panel-setting-block__header">
                        <div class="left-panel-setting-block__title fs-5">输入标题</div>
                    </div>
                    <a-textarea
                        v-model="mark_ai"
                        :auto-size="{ minRows: 3, maxRows: 5 }"
                        placeholder="输入您要生成的内容，如“明朝简史”"
                    />
                    <div class="left-panel-setting-block__header"style="margin-top: 10px;">
                        <div class="left-panel-setting-block__title fs-5">关联文档(40积分/次)
                        </div>
                    </div>

                    <a-select default-value="0" style="width: 100%;" @change="handleChange" >
                        <a-select-option value="0" >
                            不关联
                        </a-select-option>
                        <a-select-option :value="value.id"  v-for="(value,index) in history_data">
                            {{value.pdf_name}}
                        </a-select-option>
                    </a-select>
                    <br>
                    <a href="/#/ai_pdf" style="margin-top: 5px;font-size: 12px;float: right;">没有文档？去上传</a>
                    <br>
                    <div class="form_tools" v-show="eckd">
                        <div class="left-panel-setting-block__title fs-5">模型版本</div>
                        <a-radio-group v-model="modeType" button-style="" size="small">
                            <a-radio-button :value="item.type" v-for="(item, index) in modeList" :key="'g_mode_' + index">{{ item.name }}（{{ item.usable }}积分）</a-radio-button>
                        </a-radio-group>
                    </div>
                    <a-button type="primary" class="w-100 mt-2 mb-2" :loading="langding" @click="send_siwei(10)" style="margin-top: 10px;margin-bottom: 5px;">
                        开始生成
                    </a-button><br>
                    <span style="font-size: 10px;color:red">*注意：点击生成后大概需要1-2分钟生成、排版、输出，请耐心等待，不要退出。</span><br>
                    <div class="left-panel-setting-block__header" style="margin-top: 10px;">
                        <div class="left-panel-setting-block__title fs-5"> 生成的内容</div>
                    </div>
                    <a-textarea
                        style="height: 10vh;margin-top: 10px;margin-bottom: 10px;"
                        v-model="mark_value"
                        :auto-size="{ minRows: 10, maxRows: 20 }"
                        placeholder="点击开始生成后这里将自动生成对应内容"
                    />
                    <div v-show="eckd">
                        <div class="left-panel-setting-block__header">
                            <div class="left-panel-setting-block__title fs-5">输入节点内容，深入拓展</div>
                        </div>
                        <a-input
                            style="width: 60%"
                            v-model="mark_ai2"
                            :auto-size="{ minRows: 3, maxRows: 5 }"
                            placeholder="输入您要拓展的内容”"
                        />
                        <a-button type="primary"  class="w-100 mt-2 mb-2" :loading="langding"  :disabled="send_loads" @click="send_siwei(16)"
                                  style="margin-top: 0px;margin-bottom: 5px;margin-right: 10px;float: right;width:30%">
                            再次生成
                        </a-button>
                    </div>
                </div>
                <a-collapse style="width: 100%;margin-top: 10px;">
                    <a-collapse-panel header="历史记录"  key="1" >
                        <div style="height: 200px;overflow-y: auto;">
                            <div class="advanced_box"  v-for="(item, index) in contentlist">
                                <div style="margin-top: 5px;padding: 5px;" @click="takemakelist(index)"><span style="display: block;     color:#1890ff;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;width: 260px;">{{item.message_input}}</span></div>
                            </div>
                        </div>
                    </a-collapse-panel>
                </a-collapse>
            </div>
        </div>
        <div class="editor-panel__content flex-1 mark_ai">
            <div class="main xl:pb-0 ai-siwei">
                <div class="generate-result-area__container">
                    <div class="generate-empty absolute" v-if="!mark_values" style="margin-top: 50px;">
                        <!-- <a-icon type="file-markdown" theme="twoTone"  /> -->
                        <svg t="1701935578094" style="height: 70px;" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3360" width="200" height="200"><path d="M332.799002 686.081014m-332.799002 0a332.799002 332.799002 0 1 0 665.598003 0 332.799002 332.799002 0 1 0-665.598003 0Z" fill="#DFDFF2" p-id="3361"></path><path d="M883.19735 1024h-639.99808A141.055577 141.055577 0 0 1 102.399693 883.200422v-742.397772A141.055577 141.055577 0 0 1 243.19927 0.003072h516.350451a89.087733 89.087733 0 0 1 63.231811 25.599923l189.695431 189.695431A38.399885 38.399885 0 0 1 1023.996928 243.202342v639.99808a141.055577 141.055577 0 0 1-140.799578 140.799578zM243.19927 76.802842A63.999808 63.999808 0 0 0 179.199462 140.80265v742.397772A63.999808 63.999808 0 0 0 243.19927 947.20023h639.99808a63.999808 63.999808 0 0 0 63.999808-63.999808V259.074295l-179.199462-179.199463a12.799962 12.799962 0 0 0-8.447975-3.07199z" fill="#434260" p-id="3362"></path><path d="M354.558936 399.105875h40.959877L436.73469 512.001536c5.119985 15.103955 9.727971 30.463909 15.103954 45.823863h1.535996c5.119985-15.359954 9.471972-30.719908 14.847955-45.823863l40.959877-114.175657h41.215877v226.047321h-33.5359V512.001536c0-20.223939 2.815992-49.407852 4.607987-69.88779l-18.175946 51.199846-39.423882 107.775677h-22.015934l-39.679881-107.775677-17.919946-51.199846c1.535995 20.479939 4.351987 49.663851 4.351987 69.88779v111.871664h-34.047898zM609.534171 399.105875h58.623825c69.375792 0 109.055673 38.399885 109.055672 112.127663s-39.679881 113.919658-107.263678 113.919659h-60.415819zM665.598003 595.713285c48.383855 0 74.495777-28.671914 74.495777-84.735746s-25.599923-83.19975-74.495777-83.19975h-20.479938v167.935496z" fill="#434260" p-id="3363"></path></svg>
                        <div class="generate-empty__title" style="margin-top: 10px;">暂无思维导图生成</div>
                        <div class="generate-empty__desc">快去左侧输入你的思维描述吧~</div>
                    </div>
                    <div class="absolute generate-empty relative h-full w-full"  style="margin-top: 50px;" v-show="mark_values">
                        <iframe :src="baiduUrl" :key="baiduUrl" id="fsss" width="100%" height="100%" style="border: 0px;border-radius: 5px;"></iframe>
                    </div>
                </div>


                <!--end-->
            </div>
        </div>
    </main>
</template>
<script setup>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { Loading} from 'element-ui';
export default {
    // components:{Transformer},
    data() {
        return {
            mark_ai:'',
            mark_ai2:'',
            mark_ais:'',
            casedata: [],
            newsid:{},
            jzmark:true,
            send_loads:true,
            mark_value:'',
            baiduUrl:'',
            up_des_load:false,
            siwei_limit:0,
            counter:[],
            drawer:false,
            mark_values:false,
            mm:'',
            dataText:'',
            group_id:'',
            langding:false,
            send_load:true,
            meihuamd:'',
            contentlist:[],
            history_data:[],
            zsk_id:0,
            eckd:true,
            modeType: "" ,// 选中模型
            modeList: [], // 模型列表
            timerId:null
        }
    },


    created() {
        this.newsid = this.$route.query
        this.getmakelist()
        this.getUserPdf()
        this.getModeList()
    },
    computed: {
        ...mapGetters("user", ["token", "userInfo"]),
    },

    methods: {
        ...mapActions("user", ["getUserInfo"]),
        handleClick(){
            this.clickTimer = setTimeout(() => {
                this.mark_value = this.mark_value + ' ';
            }, 1000);

        },
        getModeList() {
            this.$http("chat.mode").then(res => {
                if (res.code === 1) {
                    this.modeList = res.data
                    this.modeType = res.data[0].type
                }
            })
        },
        handleChange(value){
            this.zsk_id = value;

            if(this.zsk_id>0){
                this.eckd=false
            }else{

                this.eckd=true
            }
        },
        getUserPdf(){
            this.$http("role.getpdf").then(res => {
                if(res.code == 1){
                    this.history_data = res.data;
                }

            })
        },
        takemakelist(id){

            this.mark_value = this.contentlist[id].response+'```';
            const regex = /#([^`]*)`{3}/; // 正则表达式，匹配第一个 # 号到 ' 号之间的内容
            const match = this.mark_value.match(regex);
            this.mark_value = '#'+ match[1];
            this.baiduUrl ='https://www.osaigc.cn/mark_edit/#/?markdowns=' + encodeURIComponent(this.mark_value);

        },
        getmakelist(){
            this.$http("markdown.getmakelist", {}).then(res => {
                if (res.code === 1) {
                    this.contentlist =res.data
                    // console.log(this.contentlist)
                }else{
                    this.$message.warning(res.msg)
                }
            })
        },
        generateMindMap() {
            if(this.mark_value){
                this.mark_values =true;
                this.send_loads = false;
                if(this.mark_value.includes('```markdown')){
                    this.mark_value = this.mark_value + '```';
                    const regex = /#([^`]*)`{3}/; // 正则表达式，匹配第一个 # 号到 ' 号之间的内容
                    const match = this.mark_value.match(regex);
                    this.mark_value = '#'+ match[1];
                    this.baiduUrl ='https://www.osaigc.cn/mark_edit/#/?markdowns=' + encodeURIComponent(this.mark_value);
                }else{
                    this.baiduUrl ='https://www.osaigc.cn/mark_edit/#/?markdowns=' + encodeURIComponent(this.mark_value);
                }
            }else{
                this.mark_values =false;
                this.send_loads = true;
            }
        },
        // 发送内容
        send_siwei(is){
            // console.log(is);
            if(is!=10){
                this.mark_ais = this.mark_value+'《'+ this.mark_ai2+'》'
            }else{
                this.mark_ais = this.mark_ai
            }
            if (!this.modeType) return this.$message.error("会话模型错误，请联系管理员")
            this.langding=true
            if(!this.group_id){
                this.createRoleChat(is)
            }else{
                // console.log(this.group_id)
                const obj = {
                    type: "rule",
                    prompt_id: is,
                    group_id:this.group_id,
                    message: this.mark_ais,
                    mode: this.modeType,
                    zskid:this.zsk_id
                }
                this.fetchDataStream(obj)
            }
        },
        async createRoleChat(is) {
            // console.log(is)
            if(is!=10){
                this.mark_ais = this.mark_value+'《'+this.mark_ai2+'》'
            }else{
                this.mark_ais = this.mark_ai
            }
            this.$http("role.create", { prompt_id:is }).then(res => {
                if (res.code === 1) {

                    this.group_id = res.data.group_id
                    // console.log(verify)
                    const obj = {
                        type: "rule",
                        prompt_id: is,
                        group_id:this.group_id,
                        message: this.mark_ais,
                        mode: this.modeType,
                        zskid:this.zsk_id
                    }
                    this.fetchDataStream(obj)
                }
            })
        },

        async fetchDataStream(message) {
            if (!message) {
                // console.log("输入为空")
                return
            }
            this.dataText = ""
            this.disabled = true
            const postData = message,
                url = this.$BASE_API + "/addons/chatgpt/web/sendText",
                controller = new AbortController(),
                Token = this.token,
                Sign = window.location.search.replace(/\?/g, "")

            try {
                const response = await fetch(url, {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                        Token,
                        Sign
                    },
                    body: JSON.stringify(postData),
                    signal: controller.signal
                })

                const reader = response.body.getReader()
                let data = ""
                while (true) {
                    const { done, value } = await reader.read(),
                        str = new TextDecoder().decode(value)
                    if (str.indexOf("data: [DONE]") != -1 || str.indexOf("data:[DONE]") != -1 || done) {
                        const arr = str.replaceAll(" ", "").split("data:[DONE]")
                        if (arr[0].length) {
                            this.dataText += arr[0]
                        }
                        this.disabled = false
                        break
                    }
                    data += str
                    this.dataText = data
                    this.mark_value = this.dataText;
                }
                this.mark_value = this.dataText;
                this.langding=false
            } catch {
                this.langding=false
            }
            if(this.mark_value.includes('```markdown')){
                this.mark_value = this.mark_value + '```';
                const regex = /#([^`]*)`{3}/; // 正则表达式，匹配第一个 # 号到 ' 号之间的内容
                const match = this.mark_value.match(regex);
                this.mark_value = '#'+ match[1];
                this.baiduUrl ='https://www.osaigc.cn/mark_edit/#/?markdowns=' + encodeURIComponent(this.mark_value);
            }
        },
    },
    watch: {
        mark_value(mark_value) {
            if (!this.timerId) {
                this.timerId = setTimeout(() => {
                    this.generateMindMap();
                    this.send_load = false;
                }, 3000);
            }
        },
    },
}
</script>
<style scoped>
.draw-list-left, .person-list-left {
    box-shadow: 0 2px 5px #00000014;
    height: 80vh;
    z-index: 9;
}
.justify-content-between {
    justify-content: space-between!important;
}
.pt-4 {
    padding-top: 1rem;
}
.pb-0 {
    padding-bottom: 0;
}
.bg-\[\#fafbfc\] {
    --tw-bg-opacity: 1;
    background-color: #fafbfc;
    background-color: rgb(250 251 252/var(--tw-bg-opacity));
}
.flex-col {
    flex-direction: column;
}
.h-full {
    height: 100%;
}
.flex {
    display: flex;
}
.relative {
    position: relative;
}
.p-2 {
    padding: .5rem!important;
}
.overflow-auto {
    overflow: auto!important;
}
.p-2 {
    padding: .5rem;
}
.overflow-auto {
    overflow: auto;
}
.editor-panel__title-wrap {
    align-items: center;
    color: #222529;
    display: flex;
    margin-bottom: 16px;
}
.arco-textarea-wrapper {
    background-color: var(--color-fill-2);
    border: 1px solid transparent;
    border-radius: var(--border-radius-small);
    box-sizing: border-box;
    color: var(--color-text-1);
    cursor: text;
    display: inline-flex;
    display: inline-block;
    font-size: 14px;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    transition: color .1s linear,border-color .1s linear,background-color .1s linear;
    width: 100%;
}
.arco-btn.arco-btn-primary:not(.arco-btn-disabled):not(.arco-btn-not-loading) {
    box-shadow: 0 1px 1px #00000026;
}
.arco-btn-primary, .arco-btn-primary[type=button], .arco-btn-primary[type=submit] {
    background-color: rgb(var(--primary-6));
    border: 1px solid transparent;
    color: #fff;
}
.left-panel-footer {
    /* background-color: #fff; */
    position: relative;
    bottom: 0;
    /* box-shadow: 0 -1px #00000014; */
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    width: 100%;
    z-index: 100;
}
.left-panel-footer__tips {
    display: flex;
    justify-content: space-between;
}
.left-panel-footer__spend-tip {
    align-items: center;
    color: #000;
    display: flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.disclaimer, .left-panel-footer__button-group {
    display: flex;
}
.disclaimer {
    align-items: center;
    color: #7f8792;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.disclaimer, .left-panel-footer__button-group {
    display: flex;
}
.arco-btn-group {
    align-items: center;
    display: inline-flex;
}
.arco-btn.arco-btn-primary:not(.arco-btn-disabled):not(.arco-btn-not-loading) {
    box-shadow: 0 1px 1px #00000026;
}
.arco-btn-group .arco-btn:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.arco-btn-group .arco-btn-primary:not(:last-child) {
    border-right: 1px solid rgb(var(--primary-5));
}
.arco-btn-primary, .arco-btn-primary[type=button], .arco-btn-primary[type=submit] {
    background-color: rgb(var(--primary-6));
    border: 1px solid transparent;
    color: #fff;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.arco-btn-size-large {
    border-radius: var(--border-radius-small);
    font-size: 14px;
    height: 36px;
    padding: 0 19px;
}
.arco-btn {
    align-items: center;
    -webkit-appearance: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-weight: 400;
    justify-content: center;
    line-height: 1.5715;
    outline: none;
    position: relative;
    transition: all .1s linear;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    white-space: nowrap;
}
.flex-1 {
    margin-top: 0px;
    flex: 1 1 0%;
    margin-left: 40px;
    /* height: 500px; */
}
.main {
    height: 84vh;
}
.main, .main .chat-body {
    transition: all .2s ease-in-out;
}
.main {
    flex: 1;
    order: 3;
    overflow: hidden;
}
.editor-panel {
    display: flex;
    height: 100%;
    width: 100%;
}
.editor-panel__right-panel {
    flex: 1;
    height: 84vh;
    overflow-x: auto;
}
.ai-generate-area {
    height: 100%;
    overflow-x: hidden;
    position: relative;
    width: 100%;
}
.generate-result-area__container {
    height: 84vh;
    /* margin: 0 auto; */
    margin-top: -55px;
    min-height: 650px;
    width: 100%;
}
.ai-siwei .generate-empty {
    height: 84vh;
    width: 70%;
}
.generate-empty {
    align-items: center;
    border: 2px dashed #b4b8bf;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.absolute {
    position: absolute;
}
.editor-panel {
    display: flex;
    height: 100%;
    width: 100%;
}
.editor-panel__right-panel {
    flex: 1;
    height: 84vh;
    overflow-x: auto;
}
.d-sm-flex {
    display: flex!important;
}

.align-items-center {
    align-items: center!important;
}
.disclaimer .gd_design_icon {
    height: 16px;
    margin-right: 4px;
    width: 16px;
}

.gd_design_icon {
    font-size: 20px;
    font-size: var(--icon-font-size-inherit,20px);
    height: 1em;
    overflow: hidden;
    vertical-align: -.15em;
    width: 1em;
}
.left-panel-setting-block__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    line-height: 1em;
    margin-bottom: 4px;
    padding: 7px 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.left-panel-setting-block__title {
    align-items: center;
    display: flex;
    font-weight: 700;
}
.cursor-pointer {
    cursor: pointer;
}
</style>
