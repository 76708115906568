<template>
    <div class="wrap" v-loading="loading" :element-loading-text="load_desc" style="width: 100%;padding: 20px; background: radial-gradient(circle at 12% 55%,rgba(33,150,243,.15),hsla(0,0%,100%,0) 25%);">
        <div class="left">
            <div style="padding: 10px;">
				<div class="left-panel-setting-block__header">
				    <div class="left-panel-setting-block__title fs-5">输入标题(题纲或主要内容)</div>
				</div>
                <a-textarea placeholder="例如：人工智能产业结构及未来发展" allow-clear v-model="desc" :rows="4" />

		<a-button   class="w-100 mt-2 mb-2"  @click.stop="showModal" style="margin-top: 10px;width: 100%;" >
		    设置高级选项(主题及复杂度)
		</a-button>
            <a-modal
                    title="高级选项"
                    :visible="visible"
                    :confirm-loading="confirmLoading"
                    @ok="handleOk()"
                    @cancel="handleCancel"
                    okText="确认"
                    cancelText="取消"
                    width="80%"
                >
                <h6>选择主题模板</h6>
                <div class="theme" >
                    <div class="theme_box" v-for="(value,index) in theme_data" @click="toggleClass(index,value.key)" :class="{ active: isActive(index) }">
                        <img :src="value.thumbnail">
                        <div>{{value.name}}</div>
                    </div>

                </div>
                <h6>复杂度</h6>
                <div>
                    <a-radio-group v-model="fvalue" @change="onChange">
                        <a-radio :value="1">
                            标准版
                        </a-radio>
                        <a-radio :value="2">
                            复杂版
                        </a-radio>
                    </a-radio-group>
                </div>
            </a-modal>
				<a-button type="primary" class="w-100 mt-2 mb-2" :loading="btn_loading" @click="send()" style="margin-top: 10px;margin-bottom: 5px;width: 100%;">
				    开始生成 (200积分/次 会员免费)
				</a-button><br><span style="font-size: 10px;color:red">*注意：点击生成后大概需要2-5分钟生成。</span><br>
            </div>
            <div style="margin-top: 10px;" class="history" v-if="history_data.length !=  0">
                <div><h5>历史记录</h5></div>
                <div class="history_box" v-for="(value,index) in history_data" >
                    <div @click="bianji(value.pptx_url)">
                        <img v-if="value.img_first" :src="value.img_first">
                        <img v-if="!value.img_first" :src="history_default">
                    </div>
                    <div style="text-align: center">
                        {{value.ppt_text}}
                    </div>
                    <div v-if="value.status == 3" class="status_icon error">
                        <i class="el-icon-error"></i>生成失败
                    </div>
                    <div v-if="value.status == 2" class="status_icon success">
                        <i class="el-icon-success"></i>生成成功
                    </div>
                    <div v-if="value.status == 1" class="status_icon wait">
                        <i class="el-icon-info"></i>生成中
                    </div>
					<div v-if="value.status == 2"  class="download_icons">
					    <a @click="bianji(value.pptx_url)" ><i class="el-icon-edit"></i>在线编辑</a>
					</div>
					<div v-if="value.status == 2" class="download_icon">
					    <a :href="value.pptx_url" download><i class="el-icon-download"></i>下载PPT</a>
					</div>
                </div>


            </div>
        </div>
		<div class="right"  >
		     <iframe :src="baiduUrl" width="100%" height="100%" style="border: 1px solid #ccc;border-radius: 15px;margin-top: -40px;"></iframe>
		</div>
    </div>
	

</template>
<script>
export default {
    data() {
        return {
            history_default:"https://osaigc-1307379943.cos.ap-nanjing.myqcloud.com/uploads/20231128/deebfac0dff30b446e39a841828aa45f.png",
            image:[{"url":"https://osaigc-1307379943.cos.ap-nanjing.myqcloud.com/uploads/20231128/deebfac0dff30b446e39a841828aa45f.png"}],
            desc:'',
			baiduUrl:'https://www.osaigc.cn/mark_ppt_edit/?url=',
			editshow:true,
            loading:false,
            btn_loading:false,
            load_desc:"拼命生成中,大概耗时2-5分钟，请您耐心等待",
            history_data:[],
			content_level:'standard',
			fvalue:1,
			theme_data:[],
			activeDivs:[],
			theme_key:'',
			visible:false,
            text:"https://download.yoojober.com/chatppt_business/2023-11/4838d61116e05834f283a7454e104f55.pptx?e=1701145672&token=8_2qFlGEVQZPpFvHdGR6gg2t9A9QZfWT9wwTl92s:VMX2fbqE5TAcHQmKWiVomXC6lxE="
        };
    },
    created() {
        this.getUserPpt();
		this.pptTheme();
    },
    methods: {
		handleOk() {
		    this.visible = false;
		    // this.confirmLoading = true;
		
		},
		handleCancel(e) {
		    this.visible = false;
		    this.theme_key = ''
		},
		showModal() {
		    this.visible = true;
		},
		toggleClass(index,key) {
		    this.activeDivs = [];
		    this.activeDivs.push(index);
		    this.theme_key = key
		},
		isActive(index) {
		    return this.activeDivs.includes(index);
		},
		pptTheme(){
		    this.$http("role.get_ppt_theme").then(res => {
		        if(res.data.flag){
		            this.theme_data = res.data.data;
		        }
		    })
		},
		onChange(e) {
		    if(e.target.value == 2){
		        this.content_level = 'complex';
		    }else{
		        this.content_level = 'standard';
		    }
		},
		bianji(url){
			 this.editshow = false;
			 this.baiduUrl ='https://www.osaigc.cn/mark_ppt_edit/?url=' + url;
		},
		guanbi(){
			this.editshow = true;
		},
        getImgUrl(i) {
            return this.image[i].url
        },
        // send(){
        //     this.loading = true
        //     this.btn_loading = true
        //     // console.log(this.desc);
        //     this.$http("role.chatppt", { desc:this.desc }).then(res => {
        //         if(res.code == 1){
        //             // console.log(res.data.data.id);
        //             this.getDrawingResult(res.data.data.id);
        //             // this.getDrawingResult(111);
        //         }

        //     })
        // },
		send(){
		    this.loading = true
		    this.btn_loading = true
		    if(this.desc ==''){
		        this.loading = false;
		        this.btn_loading = false;
		        this.$message.info('请输入标题');
		        return false
		    }
		    this.$http("role.chatppt", { desc:this.desc,theme:this.theme_key,content_level:this.content_level }).then(res => {
		        if(res.code == 1){
		            console.log(res.data.data.sid);
		            this.getDrawingResult(res.data.data.sid);
		        }
		    })
		},
        ppt_down(){

        },
        history_img(img_data){
			this.editshow = true;
            this.image = img_data;
        },
        // ppt结果
      //   getDrawingResult(id) {
      //       this.$http("role.pptresult", { id: id }).then(res => {
      //           this.load_desc = res.data.state_description
      //           if(res.data.images_url[0] != undefined){
      //               this.loading = false;
      //               this.image = res.data.images_url;
      //           }
      //           console.log(res.data.status)
      //           if(res.data.status == 2){
      //               this.btn_loading = false
					 // this.getUserPpt();
      //               this.$message.success('生成成功');
      //           }
      //           if(res.data.status == 3){
      //               this.loading = false;
      //               this.btn_loading = false
      //               this.getUserPpt();
      //               this.$message.error('生成失败');
      //           }
      //           if (res.data.status == 1) {
      //               const timer = setTimeout(() => {
      //                   this.getDrawingResult(id)
      //                   clearTimeout(timer)
      //                   this.$message.info(this.load_desc);
      //               }, 5000)
      //           }


      //       })
      //   },
	  getDrawingResult(id) {
	      this.$http("role.pptresult", { id: id }).then(res => {
	          if(res.data.data.status == 2){
	              this.btn_loading = false
	              this.loading = false;
	  			this.getUserPpt();
				 this.bianji(res.data.data.pptUrl);
	              this.$message.success('生成成功');
	          }
	          if(res.data.data.status  == 3){
	              this.loading = false;
	              this.btn_loading = false
	              this.getUserPpt();
	              this.$message.error('生成失败');
	          }
	          if (res.data.data.status  == 1) {
	              const timer = setTimeout(() => {
	                  this.getDrawingResult(id)
	                  clearTimeout(timer)
	                  this.$message.info(this.load_desc);
	              }, 5000)
	          }
	      })
	  },
        getUserPpt(){
            this.$http("role.getppt").then(res => {
                console.log(res);
                if(res.code == 1){
                    this.history_data = res.data;
                    // console.log(this.history_data.length)
                }

            })
        }
    },

};
</script>
<style scoped>
.wrap{
    display: flex;

}
.left{
    width: 20%;
   height: 80vh;
}
.right{
	margin-top: 40px;
    width: 79%;
	margin-left:1%
}
.history{
    height: 45vh;
    width: 100%;
    background-color: #fff;
    padding: 15px;
    overflow-y: auto;


}
.history img{
    width: 100%;
}
.history_box{
    padding: 10px 0;
    cursor: pointer;
    position: relative;
}
.status_icon{
    position: absolute;
    z-index: 99;

    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    top: 20px;
    left: 10px;
    /*border: 1px solid red;*/
}
.download_icon{
    position: absolute;
    z-index: 99;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    top: 20px;
    right: 10px;
    background-color: green;
}
.download_icons{
    position: absolute;
    z-index: 99;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    bottom: 40px;
    right: 10px;
    background-color: #1890ff;
}
.download_icon a{
    color: #fff;

}
.download_icons a{
    color: #fff;

}
.error{
    background-color: red;
}
.success{
    background-color: green;
}
.wait{
    background-color: grey;
}
</style>
<style scoped>
/* For demo */
.ant-carousel >>> .slick-dots {
    height: auto;
}
.ant-carousel >>> .slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin-left: 50%;
	transform:translate(-50%) ;
    width: 1100px;
	max-height: 600px;
}
.ant-carousel >>> .slick-thumb {
    bottom: -40px;
}
.ant-carousel >>> .slick-thumb li {
    width: 60px;
    height: 45px;
}
.ant-carousel >>> .slick-thumb li img {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
}
.ant-carousel >>> .slick-thumb li.slick-active img {
    filter: grayscale(0%);
}

.ant-carousel >>> .slick-slide {
	
  text-align: center;
  height: 620px;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  opacity: 0.8;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}

.theme{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
	height: 300px;
	overflow-y: scroll;
}
.theme .theme_box{
    width: 20%;
    padding: 15px;
    cursor: pointer;
}
.theme .theme_box div{
    text-align: center;
}
.theme .theme_box img{
    width: 100%;
	border-radius: 5px;
	
}
.active {
    border: 1px solid #3e97ff;
	border-radius: 5px;
}
</style>
