<template>
    <div class="wrap" v-loading="loading" :element-loading-text="load_desc" style="width: 100%;padding: 20px; background: radial-gradient(circle at 12% 55%,rgba(33,150,243,.15),hsla(0,0%,100%,0) 25%);">
        <div class="left">
			
			<a-tabs type="card" @change="callback">
			
			    <a-tab-pane key="3" tab="智能写作">
			      <div style="padding: 10px;">
			      	<div class="left-panel-setting-block__header">
			      	    <h5>标题、内容关键词</h5>
			      	</div>
			          <a-textarea placeholder="例如：请输入创作的标题和主要内容" allow-clear v-model="desc3" :rows="4" />
					  <div class="left-panel-setting-block__header" style="margin-top: 10px;">
						  <h5>风格</h5>
					  </div>
						  <a-radio-group v-model="fg_value" @change="onChanges">
						      <a-radio :value="1">
						        专业
						      </a-radio>
						      <a-radio :value="2">
						        学术
						      </a-radio>
						      <a-radio :value="3">
						        幽默
						      </a-radio>
						      <a-radio :value="4">
						        科普
						      </a-radio>
							  <a-radio :value="5">
							    新闻
							  </a-radio>
							  <a-radio :value="6">
							    散文
							  </a-radio>
							  <a-radio :value="7">
							    日记
							  </a-radio>
							  <a-radio :value="8">
							    公文
							  </a-radio>
							  <a-radio :value="9">
							    通知
							  </a-radio>
							  <a-radio :value="10">
							    规章制度
							  </a-radio>
							  <a-radio :value="11">
							    论文
							  </a-radio>
						    </a-radio-group>
			      	<!-- <span style="font-size: 10px;color:red">*注意：点击生成后大概需要2-5分钟生成，请耐心等待。</span><br> -->
			      	<a-button type="primary" class="w-100 mt-2 mb-2" :loading="btn_loading" @click="send_siwei(17)" style="margin-top: 10px;margin-bottom: 5px;">
			      	    开始生成 (20积分/次 * 会员免费)
			      	</a-button>
			      </div>
			    </a-tab-pane>
				<a-tab-pane key="4" tab="创意故事">
				 <div style="padding: 10px;">
				 	<div class="left-panel-setting-block__header">
				 	    <h5>剧情描述</h5>
				 	</div>
				     <a-textarea placeholder="例如：男主一觉醒来,发现自己穿越了,四周一片漆黑,但天上挂着三个月亮,显然这并不是地球!" allow-clear v-model="desc4" :rows="4" />
				 
				 	<a-button type="primary" class="w-100 mt-2 mb-2" :loading="btn_loading" @click="send_siwei(18)" style="margin-top: 10px;margin-bottom: 5px;">
				 	    开始生成 (20积分/次 * 会员免费)
				 	</a-button>
				 </div>
				</a-tab-pane>
			  </a-tabs>
			
           <div style="margin-left: 10px;"><h5>历史记录</h5></div>
            <div style="margin-top: 10px;" class="history" >
                
                <div class="history_box" v-for="(value,index) in contentlist"  
				    @click="takecontentlist(value.id)"
			         :key="index"
			         v-on:mouseenter="handleMouseEnter(index)"
			         v-on:mouseleave="handleMouseLeave(index)" 
					 v-if="contentlist.length !=  0">
					<div class="hb_left">
					    <img style="height:40px" src="../assets/imgs/word.png">
					</div>
					<div class="hb_right">
					    <div v-if="!value.is_edit">{{value.title}}</div>
					    <div class="pdf_name" v-if="value.is_edit"><a-input v-model="value.title" /></div>
					    <div>{{value.id}}:{{value.creattime}}</div>
					</div>
					<div class="icon" >
					    <div>
					        <a-icon v-if="!value.is_edit" style="font-size: 18px" @click.stop="edit_icon(index)" type="edit" />
					        <a-icon v-if="value.is_edit" style="font-size: 18px" @click.stop="edit_pdf_name(index,value.id)" type="check" />
					    </div>
					    <div>
					        <a-icon @click.stop="showModal(value.id)" style="font-size: 18px" type="delete" />
					    </div>
					</div>
					<a-modal
					    title="删除会话"
					    :visible="visible"
					    :confirm-loading="confirmLoading"
						ok-text="确定"
						cancel-text="取消"
					    @ok="handleOk(del_id)"
					    @cancel="handleCancel"
					>
					    <p>{{ ModalText }}</p>
					</a-modal>
                    
                </div>
            </div>
			
		
			
			
			
			
        </div>
        <div class="right" >
			<div id="textAreaTemplate">
			  <div style="text-align: right; margin-bottom: 20px">
			    <el-upload
			      class="uploadBtn"
			      style="display:none"
			      ref="fileRefs"
			      action=""
			      :before-upload="beforeUpload"
			      :show-file-list="false"
			      :disabled="disabled"
			    >
			      <el-button :disabled="disabled">导入</el-button>
			    </el-upload>
			  </div>
			  <!-- //富文本插件 -->
			
			  <div class="tinymcebox" id="copys">
			    <editor placeholder="输入文本内容" :init="init" :disabled="disabled" id="vue-tinymce"></editor>
			  </div>
			</div>
        </div>
		
    </div>

</template>
<script setup>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
// import TinymceText from '../components/Cards/TinymceText.vue'
import htmlDocx from "html-docx-js/dist/html-docx";
import "mammoth/mammoth.browser.js";
import Mammoth from "mammoth";
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/icons/default/icons";
import "tinymce/themes/silver";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/preview";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/advlist";
import "tinymce/plugins/codesample";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/autolink";
import "tinymce/plugins/directionality";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/template";
import "tinymce/plugins/charmap";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/autosave";
import "tinymce/plugins/autoresize";

export default {
	components:{
		Editor,
	},
	watch:{
	  // content(newValue){
	  //   this.content = newValue
	  // } 
	},
    data() {
        return {
			del_id:'',
			visible: false,
			confirmLoading: false,
			is_edit:false,
			ModalText: '删除后不可找回，确认删除当前记录？',
			modeType:"wxyy",
			tinymceId : "vue-tinymce",
			disabled: false,
			content:'',
			is_type:'',
			//初始化配置
			init: {
			  _target: () => this,
			  //menubar: true, // 菜单栏显隐
			  language_url: "/tinymce/langs/zh_CN.js",
			  //language_url: '../../static/tinymce/langs/zh_CN.js', // vue-cli2.x
			  language: "zh_CN",
			  skin_url: "/tinymce/skins/ui/tinymce-5",
			  //skin_url: '../../static/tinymce/skins/ui/oxide', // vue-cli2.x
			  content_css: '/tinymce/skins/content/default/content.css',// vue-cli2.x
			  height: 770,
			  min_height: 770,
			  max_height: 770,
			  toolbar_mode: "​scrolling",
					branding: false, //是否禁用“Powered by TinyMCE”
					menubar: false, //顶部菜单栏显示
					promotion: false,
					image_dimensions: false, 
					statusbar: false, //状态栏
					paste_webkit_styles: "all",
					paste_merge_formats: true,
					nonbreaking_force_tab: false,
					paste_auto_cleanup_on_paste: false,
					file_picker_types: 'file',
			   plugins:
			     "preview searchreplace autolink directionality visualblocks visualchars fullscreen image link template code codesample table charmap  nonbreaking insertdatetime advlist lists wordcount autosave autoresize",
			  toolbar:
			    " |undo redo | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough | alignleft aligncenter alignright alignjustify outdent indent formatpainter | table image charmap  pagebreak |importbtn| importbtn2|importbtns| styleselect  fontselect fontsizeselect | bullist numlist |customMenu|customMenu2||",
			  content_style: "p {margin: 5px 0;}",
			  fontsize_formats: "12px 14px 16px 18px 24px 36px 48px 56px 72px",
			  font_formats:
			    "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
			  branding: false,
					selector:  'vue-tinymce',
					contextmenu: 'customMenu | customMenu2',
					
			  setup: (editor)=> {
				this.editor = editor
			    let _this = this
				editor.on('change', function () {
					_this.changes = true;
				      // console.log('Content was modified');
				      // 在这里可以添加处理文本更改的逻辑
				    });
				editor.ui.registry.addButton("importbtns", {
				   text: "保存草稿",
				   
				   onAction:  () => {	
					   // const htmlContent = editor.getContent({ format: 'html' });
				      this.cunchu()
				   },
				 });
			    editor.ui.registry.addButton("importbtn", {
			      text: "导入word",
			      // icon:'word',
			      onAction: function () {
			        _this.$refs['fileRefs'].$refs['upload-inner'].handleClick()
			      },
			    });
				editor.ui.registry.addButton("importbtn2", {
					     text: "导出word",
						   // icon:'save',
					      // icon:'save',
					     onAction:  () => {
							const htmlContent = editor.getContent({ format: 'html' });
					        this.exportToWord(htmlContent)
					     },
					   });
				editor.ui.registry.addButton("customMenu", {
					              text:'<svg style="margin-right:5px;display:inline-table;width:15px;height:15px" t="1702010433431" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14995" width="200" height="200"><path d="M922.639594 232.709098l-67.873487-67.873487c-12.495119-12.495119-32.787193-12.495119-45.282312 0L63.475205 910.944162l113.055838 113.055838L922.539633 277.891449c12.59508-12.395158 12.59508-32.687232 0.099961-45.182351z m-70.672394 25.490043L730.714565 379.451777 708.123389 356.860601l121.252636-121.252635c1.599375-1.599375 4.098399-1.599375 5.697774 0l16.993362 16.993362c1.499414 1.499414 1.499414 3.998438-0.099961 5.597813zM585.171417 229.610308l-132.448262 72.37173-72.37173 132.348302-72.37173-132.348302-132.348301-72.37173 132.348301-72.371729 72.37173-132.448263 72.37173 132.448263zM588.270207 52.679422L641.949239 36.985553l-15.69387 53.679031 15.69387 53.679032-53.679032-15.793831-53.679032 15.793831 15.793831-53.679032L534.591175 36.985553zM249.902382 11.795392L290.086685 0l-11.795393 40.184303L290.086685 80.368606l-40.184303-11.795392-40.184303 11.795392 11.795392-40.184303L209.718079 0zM865.661851 617.158922L897.849278 607.762593l-9.39633 32.187427 9.39633 32.187426-32.187427-9.49629-32.187427 9.49629 9.496291-32.187426-9.496291-32.187427z" p-id="14996" fill="#aa55ff"></path></svg><span style="color:#aa55ff">改写</span>',
					              onAction:  ()=> {
					                  this.select_text = editor.selection.getContent({ format: "text" });
									  const cons = editor.getContent({ format: 'text' });
					                  this.ai_write_type = "content_change";
									  this.send_siweis(cons,this.select_text,this.ai_write_type)
					              },
				      });
				editor.ui.registry.addButton("customMenu2", {
					
								  text:'<svg style="margin-right:5px;display:inline-table;width:15px;height:15px" t="1702011010597" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="28317" width="200" height="200"><path d="M957.6 0C247.8 7.6 114.8 634.1 59.1 963.5c-3.9 22.8 7.8 44.2 26.6 54.3 7.2 3.9 15.5 6.1 24.4 6.1 11.8 0 22.8-4 31.6-10.7 8.8-6.8 15.5-16.4 18.5-27.8 9.3-34.9 21-66.1 35.2-93.6 45.4-87.7 116-137.5 212-149.5 193.9-24.2 342-186.2 400.6-333.8 5.6-14.1 0.7-30.2-11.9-38.6l-23.4-15.6c-16.8-11.2-19.2-35-4.9-49.3l23.6-23.6c40.8-40.8 81.7-97.8 135.4-197 12-22.1 24.6-46.3 38-72.9 0.3-0.6 0.5-1.2 0.7-1.8 1.1-4.7-2.6-9.7-7.9-9.7zM839.1 109.7c-8.1 13.7-15.9 26.3-23.5 38-24.2 37.2-46.2 65.4-69.4 88.6l-23.6 23.6c-20.4 20.4-30.5 48.6-27.7 77.3 2.8 28.7 18.3 54.4 42.3 70.4l1.2 0.8c-11.8 25.3-26.2 50.3-43 74.7-23.3 33.7-50.2 64.3-79.8 91.1-31.5 28.4-65.2 51.7-100.4 69.2-37.5 18.6-76.4 30.5-115.6 35.4-44.6 5.6-94.9 19.7-142.5 50.3-6.8 4.4-13.6 9.1-20.3 14.2-3.1 2.4-7.4-0.6-6.3-4.3 37.8-127.3 98.5-246.8 178.3-353.2l1.4-1.9s-90.4 68.3-203 264.5c-2.4 4.2-8.8 1.3-7.3-3.3 12.2-36.5 25.5-71.2 39.7-103.8 22.9-52.8 48.9-101.7 77.3-145.5 31.2-48.1 66.3-91.5 104.3-129 41.2-40.7 87.1-75.6 136.4-104 17.4-10 35.3-19.2 53.8-27.7 38.5-17.7 79.4-32.2 122.2-43.2 40.6-10.5 83.7-18.1 128.7-22.7-8.1 14.5-15.8 27.9-23.2 40.5z" p-id="28318" fill="#1890ff"></path></svg><span style="color:#1890ff">续写</span>',
					              onAction:  () => {
					                   this.select_text = editor.selection.getContent({ format: "text" });
					                   this.ai_write_type = "content_long";
									   const cons = editor.getContent({ format: 'text' });
									   this.send_siweis(cons,this.select_text,this.ai_write_type)
					              },
				      });
					  
			  },
			  paste_data_images: true,
			  // 图片上传转码
			  images_upload_handler: (blobInfo, progress) =>
			    new Promise((resolve, reject) => {
			      resolve(
			        "data:" + blobInfo.blob().type + ";base64," + blobInfo.base64()
			      );
			    }),
			},
			changes:false,
			fengge:'',
			mark_ais:'',
			baocun_id:'',
			select_text: '',
			ai_write_type: '',
			right_content: '',
			write_content_font: '',
			title: '',
			send_loading: false,
			baseUrl: '', // 请确保设置了正确的 baseUrl
			headers: {}, // 请确保设置了正确的 headers
			fg_value: 1,
            history_default:"https://osaigcimg.su.bcebos.com/uploads/20231128/deebfac0dff30b446e39a841828aa45f.png",
            image:[{"url":"https://osaigcimg.su.bcebos.com/uploads/20231128/deebfac0dff30b446e39a841828aa45f.png"}],
            desc1:'',
			desc2:'',
			desc3:'',
			desc4:'',
			baiduUrl:'',
			editshow:true,
            loading:false,
            btn_loading:false,
            load_desc:"拼命生成中,大概耗时2-5分钟，请您耐心等待",
            contentlist:[],
			contentlist:[],
			titles:'未命名',
            text:"https://download.yoojober.com/chatppt_business/2023-11/4838d61116e05834f283a7454e104f55.pptx?e=1701145672&token=8_2qFlGEVQZPpFvHdGR6gg2t9A9QZfWT9wwTl92s:VMX2fbqE5TAcHQmKWiVomXC6lxE="
        };
    },
    created() {
		this.getlist()
        // this.getUserPpt();
    },
	mounted() {
	     // this.interval = setInterval(this.cunchu, 60000); // 每5秒执行一次 incrementCount 函数
	},
	beforeDestroy() {
	    clearInterval(this.interval); // 离开组件时清除定时器
	},
	computed: {
		...mapGetters("user", ["token", "userInfo"]),
	},
    methods: {
		...mapActions("user", ["getUserInfo"]),
		showModal(id) {
			this.del_id = id;
		    this.visible = true;
		},
		handleCancel(e) {
		    this.visible = false;
		},
		edit_icon(index){
		    this.contentlist[index].is_edit = true;
		    // this.is_edit = true;
		},
		edit_pdf_name(index,id){
		   
		    this.$http("ai_write.edit_title",{id:id,title:this.contentlist[index].title}).then(res => {
		        // console.log(res);
		        if(res.code == 1){
		            this.contentlist[index].is_edit = false;
					this.$message.success('修改成功')
		        }
		    })
		},
		handleMouseEnter(index){
		    this.contentlist[index].show = true;
		
		},
		handleMouseLeave(index){
		    this.contentlist[index].show = false;
		},
		handleOk(id) {
			// console.log(id)
		    this.confirmLoading = true;
		    this.$http("ai_write.del_word",{id:id}).then(res => {
		        if(res.code == 1){
		            this.contentlist = res.data;
		                this.visible = false;
		                this.confirmLoading = false;
		                this.getlist();
		        }
		
		    })
		},
		exportToWord(html) {
		        // const ood = 'vue-tinymce'
		        // const content = document.getElementById(ood).innerHTML
				// console.log(html)
		        const converted = htmlDocx.asBlob(html)
		        const url = window.URL.createObjectURL(converted)
		  		const a = document.createElement("a");
		  		a.href = url;
		  		a.download = '导出文件.doc';
		  		a.click();
		  		URL.revokeObjectURL(url);
		      },
		// 每隔10秒自动存储word
		cunchu(){
			if(tinymce.get('vue-tinymce').getContent() && this.changes){
					this.$http("ai_write.baocun", {
						content:encodeURIComponent(tinymce.get('vue-tinymce').getContent()),
						is_type:this.is_type,
						titles:this.titles,
						id:this.baocun_id
					}).then(res => {
						if (res.code === 1) {
							this.baocun_id =res.data
							this.$message.success('保存成功')
							// this.changes = false
						}
					})
					}
			// }else{
			// 	this.$message.error('内容没有更改')
			// }
		},
		getlist(){
			this.$http("ai_write.getlist", {}).then(res => {
				if (res.code === 1) {
					this.contentlist =res.data
					
					// this.$message.success('操作成功')
				}else{
					 this.$message.warning(res.msg)
				}
			})
		},
		takecontentlist(id){
			this.$http("ai_write.takecontentlist", {id:id}).then(res => {
				if (res.code === 1) {
					// this.content = res.data.content
					this.baocun_id = res.data.id
					 tinymce.get('vue-tinymce').setContent(res.data.content);
					this.$message.success('加载完成')
				}else{
					 this.$message.warning(res.msg)
				}
			})
		},
		beforeUpload(file, fileList) {
		  const self = this;
		  var reader = new FileReader();
		  reader.onloadend = function (event) {
		    let arrayBuffer = reader.result;
		    //将word 转换成html
		    const loading = self.$loading({
		      lock: true,
		      text: '文件解析中....',
		      spinner: 'el-icon-loading',
		      background: 'rgba(0, 0, 0, 0.3)'
		    });
		    Mammoth.convertToHtml({ arrayBuffer: arrayBuffer }).then(function (
		      resultObject
		    ) {
		      
		      setTimeout(() => {
		        let content = tinymce.activeEditor.getContent()+resultObject.value
		        tinymce.activeEditor.setContent(content);
		        loading.close();
		      }, 100);
		    });
		  };
		  reader.readAsArrayBuffer(file);
		  return false;
		},
		onContentUpdated(newValue){
			this.content = newValue
		},
		callback(){
			console.log(this.content);
			console.log(this.select_text);
			console.log(this.ai_write_type);
		},
		 onChanges(e) {
		      console.log('radio checked', e.target.value);
		    },
		send_siwei(is){
			this.is_type = is
			this.baocun_id = ''
			this.btn_loading = true
			
				  if(is==17){
					  if(this.fg_value == 1){
						  this.fengge = '专业'
					  }
					  if(this.fg_value == 2){
					  						 this.fengge = '学术'
					  }
					  if(this.fg_value == 3){
					  						  this.fengge = '幽默'
					  }
					  if(this.fg_value == 4){
					  						  this.fengge = '科普'
					  }
					  if(this.fg_value == 5){
					  						  this.fengge = '新闻'
					  }
					  if(this.fg_value == 6){
					  						  this.fengge = '散文'
					  }
					  if(this.fg_value == 7){
					  						  this.fengge = '日记'
					  }
					  if(this.fg_value == 8){
					  						  this.fengge = '公文'
					  }
					  if(this.fg_value == 9){
					  						  this.fengge = '通知'
					  }
					  if(this.fg_value == 10){
					  						  this.fengge = '规则制度'
					  }
					  if(this.fg_value == 10){
					  						  this.fengge = '论文'
					  }
						this.mark_ais = "标题及关键词："+this.desc3+"，风格："+ this.fengge
						this.titles = this.desc3
				}
				if(is==18){
					this.mark_ais = "剧情及关键词："+this.desc4
					this.titles = this.desc4
				}
				if (!this.modeType) return this.$message.error("会话模型错误，请联系管理员")
				this.langding=true
				if(!this.group_id){
					this.createRoleChat(is)
				}else{
					// console.log(this.group_id)
					const obj = {
						type: "rule",
						prompt_id: is,
						group_id:this.group_id,
						message: this.mark_ais,
						mode: this.modeType
					}
					this.fetchDataStream(obj)
				}
			},
		send_siweis(cons,text,type){
			// this.baocun_id = ''
			
			if(text.length<5){
				 this.$message.error("请选择要改写或续写的内容,且必须多余5个字")
				 return 1;
			}
			this.btn_loading = true
			if(type=='content_change'){
				this.mark_ais="请根据原文'"+cons+"'改写'"+text+"'部分,仅回答改写的新内容"
			}
			if(type=='content_long'){
				this.mark_ais="请根据原文'"+cons+"'续写'"+text+"'之后的内容,仅回答续写的新内容"
			}
			
				if (!this.modeType) return this.$message.error("会话模型错误，请联系管理员")
				this.langding=true
				if(!this.group_id){
					this.createRoleChat(19)
				}else{
					const obj = {
						type: "rule",
						prompt_id: 19,
						group_id:this.group_id,
						message: this.mark_ais,
						mode: this.modeType
					}
					this.fetchDataStreams(obj)
				}
			},
		async createRoleChat(is) {
			
				this.$http("role.create", { prompt_id:is }).then(res => {
					if (res.code === 1) {
						
									this.group_id = res.data.group_id
									// console.log(verify)
									const obj = {
										type: "rule",
										prompt_id: is,
										group_id:this.group_id,
										message: this.mark_ais,
										mode: this.modeType
									}
									if(is==19){
										this.fetchDataStreams(obj)
									}else{
										this.fetchDataStream(obj)
									}
									
								}
						})
			},
		async fetchDataStreams(message) {
				if (!message) {
					console.log("输入为空")
					return
				}
				this.dataText = ""
				this.disabled = true
				tinymce.get('vue-tinymce').setProgressState(1);
				const postData = message,
					url = this.$BASE_API + "/addons/chatgpt/web/sendText",
					controller = new AbortController(),
					Token = this.token,
					Sign = window.location.search.replace(/\?/g, "")
			
				try {
					const response = await fetch(url, {
						method: "post",
						headers: {
							"Content-Type": "application/json;charset=utf-8",
							Token,
							Sign
						},
						body: JSON.stringify(postData),
						signal: controller.signal
					})
			
					const reader = response.body.getReader()
					let data = ""
			
					while (true) {
						const { done, value } = await reader.read(),
							str = new TextDecoder().decode(value)
			
						if (str.indexOf("data: [DONE]") != -1 || str.indexOf("data:[DONE]") != -1 || done) {
							const arr = str.replaceAll(" ", "").split("data:[DONE]")
							if (arr[0].length) {
								this.dataText += arr[0]
							}
							this.disabled = false
							break
						}
							data += str
							this.dataText = data
							this.content = this.dataText;
					}
					var editor = tinymce.get('vue-tinymce');
					if(this.ai_write_type=='content_long'){
					  // 获取当前光标所在的位置
					  var caretPos = editor.selection.getRng();
					  // 在光标位置处插入新的文本
					  this.content = this.select_text + "<span style='color:#1890ff'>"+this.content+"</span>";
					  editor.insertContent(this.content);
					}
					if(this.ai_write_type=='content_change'){
					   var contents = editor.getContent();
					   this.content = "<span style='color:#aa55ff'>"+this.content+"</span>";
					   var updatedContent = contents.replace(this.select_text, this.content);
					    editor.setContent(updatedContent);
					}
					this.cunchu()
					tinymce.get('vue-tinymce').setProgressState(0);
					this.btn_loading = false
					this.langding=false
				} catch {
					tinymce.get('vue-tinymce').setProgressState(0);
					this.btn_loading = false
					this.langding=false
					 this.$message.error("请求失败")
				}
				
			},	
		async fetchDataStream(message) {
				if (!message) {
					console.log("输入为空")
					return
				}
				this.dataText = ""
				this.disabled = true
				tinymce.get('vue-tinymce').setProgressState(1);
				const postData = message,
					url = this.$BASE_API + "/addons/chatgpt/web/sendText",
					controller = new AbortController(),
					Token = this.token,
					Sign = window.location.search.replace(/\?/g, "")
			
				try {
					const response = await fetch(url, {
						method: "post",
						headers: {
							"Content-Type": "application/json;charset=utf-8",
							Token,
							Sign
						},
						body: JSON.stringify(postData),
						signal: controller.signal
					})
			
					const reader = response.body.getReader()
					let data = ""
			
					while (true) {
						const { done, value } = await reader.read(),
							str = new TextDecoder().decode(value)
			
						if (str.indexOf("data: [DONE]") != -1 || str.indexOf("data:[DONE]") != -1 || done) {
							const arr = str.replaceAll(" ", "").split("data:[DONE]")
							if (arr[0].length) {
								this.dataText += arr[0]
							}
							this.disabled = false
							break
						}
							data += str
							this.dataText = data
							this.content = this.dataText;
							tinymce.get('vue-tinymce').setContent(this.content);
					}
					this.cunchu()
					tinymce.get('vue-tinymce').setProgressState(0);
					this.btn_loading = false
					this.langding=false
				} catch {
					tinymce.get('vue-tinymce').setProgressState(0);
					this.btn_loading = false
					this.langding=false
					console.error("请求失败")
				}
				
			},
		
    },

};
</script>
<style scoped>
.wrap{
    display: flex;

}
.left{
    width: 27%;

}
.right{
	margin-top: -20px;
    width: 69%;
	margin-left:1%
}
.history{
	height: 38vh;
    overflow-y: auto;
    margin: 0 -15px;
    padding: 0 15px;
}
.history .history_box{
    background-color: #fff;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
}
.history .history_box .pdf_name .ant-input{
    border:none;
    background-color: #eee;
    height: 30px;
}
.history .history_box .icon{

    display: flex;
}
.history .history_box .icon div{
    margin-left: 10px;
}
.history .history_box:hover{
    border: 1px solid #3e97ff;
}
.history .history_box .hb_left{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.history .history_box .hb_right{
    width: 54%;
}
.history .history_box .hb_right div:nth-child(2){
    font-size: 12px;
    font-family: PingFang SC,PingFang SC-Regular;
    color: #8890a9;
}
.status_icon{
    position: absolute;
    z-index: 99;

    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    top: 20px;
    left: 10px;
    /*border: 1px solid red;*/
}
.download_icon{
    position: absolute;
    z-index: 99;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    top: 20px;
    right: 10px;
    background-color: green;
}
.download_icons{
    position: absolute;
    z-index: 99;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    bottom: 40px;
    right: 10px;
    background-color: #1890ff;
}
.download_icon a{
    color: #fff;

}
.download_icons a{
    color: #fff;

}
.error{
    background-color: red;
}
.success{
    background-color: green;
}
.wait{
    background-color: grey;
}
</style>
<style scoped>
/* For demo */
.ant-carousel >>> .slick-dots {
    height: auto;
}
.ant-carousel >>> .slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin-left: 50%;
	transform:translate(-50%) ;
    width: 1100px;
	max-height: 600px;
}
.ant-carousel >>> .slick-thumb {
    bottom: -40px;
}
.ant-carousel >>> .slick-thumb li {
    width: 60px;
    height: 45px;
}
.ant-carousel >>> .slick-thumb li img {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
}
.ant-carousel >>> .slick-thumb li.slick-active img {
    filter: grayscale(0%);
}

.ant-carousel >>> .slick-slide {
	
  text-align: center;
  height: 620px;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  opacity: 0.8;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>
<style lang="scss" scoped>
#textAreaTemplate {
  .uploadBtn {
    display: inline-block;
    margin: 0 10px;
  }
  .tabTiyle {
    border-left: 8px solid rgba(36, 145, 255, 1);
    padding-left: 15px;
    font-size: 21px;
    text-align: left;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>