

<template>
 
    <main class="main overflow-auto ai-knowledge">
            <h1  style="text-align: center;margin-top: 10px;" v-if="qiyeban"> AI-Agent 插件集市</h1>
			<div  class="titles1" v-else> <span class="titles">此刻起，让AI改变你的工作方式</span></div>
				<h5 style="text-align: center;width: 100%;" v-if="qiyeban">
					众多开发者提供了多行业、多场景下的小插件，您可以直接点击使用。也可以创建一个新应用，供他人来使用并赚取一定的奖励</h5>
					<h5 style="text-align: center;width: 100%;margin-top: 20px;" v-else>
							提供了<span style="font-size: 30px;color: #1890ff;"> 97 </span>个行业的<span style="font-size: 30px;color: #1890ff;"> 1200+ </span>场景办公工具</h5>
					<div style="width: 100%;text-align: center;padding: 10px;" v-if="!qiyeban">
						<div style="width: 90%;text-align: center;margin-left: 5%;margin-top: 10px;">
						<a-input-search style="width: 20%;" placeholder="搜索工具" enter-button @search="onSearch" />
						    <a-button style="margin-left: 20px;" type="primary" icon="code"  @click="formshow = true">
						        创建应用
						      </a-button>
						    <a-button style="margin-left: 20px;" type="danger" icon="star"  @click="getmygptClassify">
						    	我的应用
						    	</a-button>
								
						</div><br />
					
					
					</div>
				
				<br>
				<div style="width: 100%;text-align: center;padding: 10px;" v-if="qiyeban">
				   <a-button type="primary" icon="code"  @click="formshow = true">
				       创建应用
				     </a-button>
					 <a-button style="margin-left: 20px;" type="danger" icon="star"  @click="getmygptClassify">
					     我的应用
					   </a-button>
				</div>
				<div style="width: 30%;text-align: center;margin-left: 35%;margin-top: 20px;" v-if="qiyeban"> 
					<a-input-search placeholder="搜索小应用" enter-button @search="onSearch" />
						<br />
				</div>
				<div style="width: 90%;text-align: left;margin-top: 20px;margin-left: 5%;" v-if="qiyeban">
					<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: '24px' }">
					<a-row type="flex" align="middle" justify="space-between">
						<a-col :span="24"  >
							<a-radio-group v-model="classifySelected" size="small" @change="getSecondClassify">
								<a-radio-button :value="item.id" v-for="(item, index) in classifyData" :key="'classify_' + item.id">{{ item.title }}</a-radio-button>
							</a-radio-group>
						</a-col>
					</a-row>
					
					<div style="margin-top: 20px;" v-for="(item, index) in classifyData" :key="'first_' + item.id">
						<a-row :gutter="[24, 24]" v-if="item.id == classifySelected">
							<a-col :span="24" :sm="12" :md="8" :lg="8" :xl="6" v-for="(item1, index1) in item.children" :key="'second_' + item1.id" @click="classifyItem(item1.id)">
								<div class="footer_box">
									<span class="icon" >
										<img :src="item1.image" style="width: 32px;height:32px" alt="" />
									</span>
									<h6>{{ item1.title }}</h6>
									<p class="c-line-2"  style="-webkit-line-clamp: 1;">{{ item1.desc }}</p>
									
									<div v-if="classifySelected!='mygpt'">
									<div class="collect" v-if="token" @click.stop="collectWriteItem(item.id, item1.id, item1.isVote)" >
										<span style="padding:5px 10px;border-radius: 5px;color: #1890ff
										;"><a-icon type="star" theme="twoTone" />收藏</span>
									</div>
									<a-button type="link"><a-icon type="eye" theme="twoTone" />{{ item1.views*65 }}</a-button>
									</div>
									<div v-else>
									<div class="collect">
										<span v-if="item1.status==2" style="padding:5px 10px;border-radius: 5px;color: orange
										;border: 1px solid orange;">审核中</span>
										<span v-if="item1.status==1" style="padding:5px 10px;border-radius: 5px;color: limegreen
										;border: 1px solid limegreen;">审核通过</span>
										<span v-if="item1.status==3" style="padding:5px 10px;border-radius: 5px;color: red
										;border: 1px solid red;">审核驳回</span>
									</div>
									<a-button type="link" style="width: 50px;"><a-icon type="right-square" theme="twoTone" style="margin-left: -20px;"/>{{ item1.usages}}</a-button>
									
									<span  v-if="item1.status==3 && token" @click.stop="collectdelectItem(item1.id)" style="padding:5px 10px;border-radius: 5px;color: red
									;"><a-icon type="delete" theme="twoTone" twoToneColor="red" />删除</span>
									<span  v-if="item1.status==2 && token" @click.stop="collecteditItem(item1.id)" style="padding:5px 10px;border-radius: 5px;color: #1890ff
									;"><a-icon type="edit" theme="twoTone"  />编辑</span>
									<span  v-if="item1.status==1 && token" @click.stop="copyShareLink(item1.id)" style="padding:5px 10px;border-radius: 5px;color: #1890ff
									;"><a-icon type="tag" theme="twoTone" />分享</span>
									</div>
									<a-button type="primary" icon="play-circle" style="float: right;height: 30px;margin-top:-35px;">运行</a-button>
								</div>
							</a-col>
						</a-row>
					</div>
					</a-card>
				</div>
				<div style="width: 90%;text-align: left;margin-left: 5%;" v-else>
					<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: '24px' }">
					<a-row type="flex" align="middle" justify="space-between">
						<a-col :span="24">
							<a-radio-group v-model="classifySelected" size="small" @change="getSecondClassify">
								<a-radio-button :value="item.id" v-for="(item, index) in classifyData" :key="'classify_' + item.id">{{ item.title }}</a-radio-button>
							</a-radio-group>
							<a-tree-select
							    v-model="hyvalue"
							    style="width: 20%;float: right;"
							    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							    :tree-data="treeData"
							    placeholder="请选择行业"
							  >
							</a-tree-select>
						</a-col>
					</a-row>
					<div style="margin-top: 20px;" v-for="(item, index) in classifyData" :key="'first_' + item.id">
						<a-row :gutter="[24, 24]" v-if="item.id == classifySelected">
							<a-col :span="24" :sm="12" :md="8" :lg="8" :xl="6" v-for="(item1, index1) in item.children" :key="'second_' + item1.id" @click="classifyItem(item1.id)">
								<div class="footer_box">
									<span class="icon" >
										<img :src="item1.image" style="width: 32px;height:32px" alt="" />
									</span>
									<h6>{{ item1.title }}</h6>
									<p class="c-line-2"  style="-webkit-line-clamp: 1;">{{ item1.desc }}</p>
									
									<div v-if="classifySelected!='mygpt'">
									<div class="collect" v-if="token" @click.stop="collectWriteItem(item.id, item1.id, item1.isVote)" >
										<span style="padding:5px 10px;border-radius: 5px;color: #1890ff
										;"><a-icon type="star" theme="twoTone" />收藏</span>
									</div>
									<a-button type="link"><a-icon type="eye" theme="twoTone" />{{ item1.views*65 }}</a-button>
									</div>
									<div v-else>
									<div class="collect">
										<span v-if="item1.status==2" style="padding:5px 10px;border-radius: 5px;color: orange
										;border: 1px solid orange;">审核中</span>
										<span v-if="item1.status==1" style="padding:5px 10px;border-radius: 5px;color: limegreen
										;border: 1px solid limegreen;">审核通过</span>
										<span v-if="item1.status==3" style="padding:5px 10px;border-radius: 5px;color: red
										;border: 1px solid red;">审核驳回</span>
									</div>
									<a-button type="link" style="width: 50px;"><a-icon type="right-square" theme="twoTone" style="margin-left: -20px;"/>{{ item1.usages}}</a-button>
									
									<span  v-if="item1.status==3 && token" @click.stop="collectdelectItem(item1.id)" style="padding:5px 10px;border-radius: 5px;color: red
									;"><a-icon type="delete" theme="twoTone" twoToneColor="red" />删除</span>
									<span  v-if="item1.status==2 && token" @click.stop="collecteditItem(item1.id)" style="padding:5px 10px;border-radius: 5px;color: #1890ff
									;"><a-icon type="edit" theme="twoTone"  />编辑</span>
									<span  v-if="item1.status==1 && token" @click.stop="copyShareLink(item1.id)" style="padding:5px 10px;border-radius: 5px;color: #1890ff
									;"><a-icon type="tag" theme="twoTone" />分享</span>
									</div>
									<a-button type="primary" icon="play-circle" style="float: right;height: 30px;margin-top:-35px;">运行</a-button>
								</div>
							</a-col>
						</a-row>
					</div>
					</a-card>
				</div>
		<div class="qas" v-if="qiyeban">
		                    <div class="mt-20">
		                        <dl class="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10" style=" width: 95%;position: relative;height: 240px;padding: 30px;margin-left: 3%;">
		                            <div>
		                                <dt class="text-base font-semibold leading-7 text-gray-900">*如何发布小应用?</dt>
		                                <dd class="mt-2 text-base leading-7 text-gray-600">用户需要先注册账号，登录后根据要求验证邮箱或手机号，然后点击创建按钮，填写相关信息，测试后创建，然后等待审核通过即可上线。</dd>
		                            </div>
									<div>
									    <dt class="text-base font-semibold leading-7 text-gray-900">*如何推广我的小应用？</dt>
									    <dd class="mt-2 text-base leading-7 text-gray-600">小应用的质量决定了受欢迎程度，您可以将应用分享链接发送给好友，平台也会给小应用带来一定流量</dd>
									</div>
									<div>
									    <dt class="text-base font-semibold leading-7 text-gray-900">*开发者奖励政策?</dt>
									    <dd class="mt-2 text-base leading-7 text-gray-600">我们希望和广大的开发者和爱好者共建GPT-Store,为客户带来更多优质的小应用。为此我们设立了奖励计划，用户在使用小应用的过程中消耗的积分将会按照一定比例的佣金奖励给开发者</dd>
									</div>
		                        </dl>
		                    </div>
		              
		</div>
<div style="height: 30px;"></div>
        <a-modal v-model="formshow" title="创建应用" :centered="true" :maskClosable="false" cancelText="取消" okText="立即创建" :confirmLoading="formloading" @ok="cdkeyConfirm">
    	  <div class="cdkey_modal">
		<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" style="height: 600px;overflow-y: auto;">
					<a-form-model-item label="应用图标:">
						<a-upload
					   name="file"
					   list-type="picture-card"
					   class="avatar-uploader"
					   :show-upload-list="false"
					   :action="upload.action"
									:headers="upload.headers"
									:accept="upload.accept" 
									:before-upload="uploadPictureBefore"
									@change="uploadPictureChange"
					 >
					   <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width: 100px;height: 100px;" />
					   <div v-else>
					     <a-icon :type="upload.loading ? 'loading' : 'plus'" />
					     <div class="ant-upload-text">
					       上传图标（200*200）
					     </div>
					   </div>
					 </a-upload>
					 </a-form-model-item>
						<a-form-model-item label="应用名称:">
						  <a-input v-model="form.name" />
						</a-form-model-item>
						<a-form-model-item label="应用简介:">
						  <a-input v-model="form.desc" />
						</a-form-model-item>
					   <a-form-model-item label="请选择分类">
						  <a-select v-model="form.fenlei" placeholder="请选择分类">
							<a-select-option :value="items.id" v-for="(items, indexs) in formclass"  style="width: 200px;">
							  {{items.title}}
							</a-select-option>
						  </a-select>
						</a-form-model-item>
						<a-form-model-item label="模板内容:" >
						      <a-input v-model="form.text" type="textarea" placeholder="例:请你扮演[变量1]角色,回答[变量2]问题" />
						</a-form-model-item>
					
						<div style="background-color: #f6f6f6;border-bottom:2px solid #fff;padding: 5px;" v-for="(itemx, indexx) in formbian" >
							<!-- <span>变量ID:{{indexx}}</span> -->
							<a-button style="margin-left: 85%;margin-top: 5px;" type="danger" @click="deleteMainSku(indexx)">
							      删除
							</a-button>
							<a-form-model-item label="变量名称:">
							  <a-input v-model="formbian[indexx].title" />
							</a-form-model-item>
							<a-form-model-item label="提示语:">
							  <a-input v-model="formbian[indexx].tip" />
							</a-form-model-item>
							<a-form-model-item label="变量参数:">
							  <a-input v-model="formbian[indexx].name" placeholder="英文字符" />
							</a-form-model-item>
							<a-form-model-item label="变量类型:">
							<a-select v-model="formbian[indexx].field" placeholder="请选择分类">
							  <a-select-option  value="string">
							   字符
							  </a-select-option>
							  <a-select-option value="number" >
							   数字
							  </a-select-option>
							  <a-select-option value="textarea">
							   文本
							  </a-select-option>
							  <a-select-option value="select">
							   下拉框
							  </a-select-option>
							</a-select>
							</a-form-model-item>
							<a-form-model-item v-if="formbian[indexx].field=='select'" label="下拉框内容" >
							      <a-input v-model="formbian[indexx].selects" type="textarea" placeholder="(每个选项以逗号,隔开):value1,value2,..." />
							</a-form-model-item>
							<!-- <a-form-model-item label="提问方式:">
							<a-select v-model="formbian[indexx].type" placeholder="替换方式"  :default-value="{ key: 'replace' }">
							  <a-select-option  value="replace">
							   模板替换-当前【变量】必须在【模板内容】中有对应的[变量]，根据用户输入或选择的内容进行替换
							  </a-select-option>
							  <a-select-option value="append" >
							   模板追加 -一般是让用户自定义输入内容并追加到【模板内容】的后面，需要添加前置语句与模板衔接
							  </a-select-option>
							</a-select>
							</a-form-model-item> -->
							
						</div>
							<a-button style="margin-top: 10px;" type="primary" @click="addMainSku()">添加变量</a-button>
				</a-form-model>
				<span style="color: red;font-size: 12px;margin-top: 5px;">*提示：您点击创建后我们将会在24小时内审核您的应用，审核通过后会展示在应用中心</span>
    	</div>
    </a-modal>
    </main>
  
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
import { apiList } from "@/api/api"
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

	export default {
		components: {},
		data() {
			return {
				formbian:[],
				labelCol: { span: 5 },
				wrapperCol: { span: 14 },  
				classifyData: [],
				formclass:[],
				classifySelected: "",
				formloading: false,
				formshow:false,
				form: {
					text: "",
					fenlei:'',
					desc:'',
					name:'',
				},
				xiugai:0,
				loading: false,
			    imageUrl: '',
				upload: {
				    loading: false,
				    action: apiList.common.upload.url,
				    headers: {
				        token: ""
				    },
				    accept: "image/png, image/jpeg",
				    data: {
				        is_user_image: 1
				    }
				},
				hyvalue: '请选择行业',
				treeData:[],
				qiyeban:1
			}
		},
		computed: {
			...mapGetters("user", ["token","qiyeban"])
		},
		created(){
			 this.upload.headers.token = this.token
			this.getFirstClassify()
			!this.qiyeban && this.gethangye()
		},
		methods: {
			 ...mapActions("user", ["getUserInfo"]),
			hyvalue(value){
				 console.log(value);
			},
			// 获取收藏分类
			// enterIconLoading(){
			// 	this.$message.info('当前开发者邀请内测制，如您想参与内测可联系客服');
			// },
			gethangye(){
				this.$http("write.gethangye").then(res => {
					if (res.code === 1) {
						this.treeData = res.data
					}
				})
			},
			async copyShareLink(id) {
				const link ='https://www.osaigc.com/pc/#/write/create?id='+id
				try {
					await navigator.clipboard.writeText(link)
					this.$message.success("已复制分享链接，快去分享给您的好友吧")
				} catch (err) {
					this.$message.error("复制失败")
				}
			},
			// 删除
			collectdelectItem(ids){
				this.$http("write.mydelete", { id:ids}).then(res => {
					if (res.code === 1) {
						this.$message.info("删除成功");
						this.getmygptClassify()
					}else{
						this.$message.info(res.msg);
						this.getmygptClassify()
					}
				})
			},
			// 修改
			collecteditItem(ids){
				this.$http("write.myedit", { id:ids}).then(res => {
					if (res.code === 1) {
						this.imageUrl = res.data.image
						this.form.fenlei = res.data.topic_id
						this.form.name =  res.data.title
						this.form.text =  res.data.prompt
						this.form.desc =  res.data.desc
						this.formbian = res.data.extend
						this.xiugai = ids
						this.formshow = true;
						
					}else{
						this.$message.info(res.msg);
					}
				})
				
			},
			addMainSku() {
			                        this.formbian.push({
			                            title: '',
			                            field: '',
			                            selects: [],
			                            name: '',
			                            type: 'replace',
										tip:'',
										value:'',
										require:1,
										text:''
			                        })
			                    },
			deleteMainSku(k) {
			                        // 删除主规格
			                        this.formbian.splice(k, 1)
			                    },
		    cdkeyConfirm(){
				if(!this.form.fenlei){
					this.$message.info("请选择分类");
					return
				}
				if(!this.form.name){
					this.$message.info("请填写应用名称");
					return
				}
				if(!this.form.text){
					this.$message.info("请填写模板内容");
					return
				}
				if(!this.form.desc){
					this.$message.info("请填写应用简介");
					return
				}
				if(!this.imageUrl){
					this.$message.info("请上传应用图标");
					return
				}
				if(!this.formbian){
					this.$message.info("请设置变量参数");
					return
				}
				this.formloading = true;
				console.log(this.datas);
				if(this.xiugai){
					this.$http("write.edit_prompts", { id:this.xiugai,form: this.form,img:this.imageUrl,formbian:this.formbian}).then(res => {
						if (res.code === 1) {
							this.$message.info("提交成功请等待审核");
							this.formloading = false;
							this.formshow = false;
							this.getmygptClassify()
						}else{
							this.$message.info(res.msg);
							this.formloading = false;
						}
					})
				}else{
					this.$http("write.add", { form: this.form,img:this.imageUrl,formbian:this.formbian}).then(res => {
						if (res.code === 1) {
							this.$message.info("提交成功请等待审核");
							this.formloading = false;
							this.formshow = false;
							
						}else{
							this.$message.info(res.msg);
							this.formloading = false;
						}
					})
				}
				
			},
			onSearch(id){
				this.$http("write.search", { search: id }).then(res => {
					if (res.code === 1) {
						this.classifyData.map((item, index) => {
							if (item.id == this.classifySelected) {
								item.children = res.data.map(({ id, title, desc, image, isVote,status,openstore,usages,votes,views }) => ({ id, title, desc, image, isVote,status,openstore,usages,votes,views }))
							}
						})
					}
				})
			},
			getCollectClassify() {
				this.$http("write.collectList").then(res => {
					if (res.code === 1) {
						let arr = []
						res.data.map(item => {
							const {
								prompt: { id, title, desc, image, isVote,status,openstore,usages,votes,views }
							} = item
							arr.push({ id, title, desc, image, isVote,status,openstore,usages,votes,views })
						})
						this.classifyData.map(item => {
							if (item.id === "collect") {
								item.children = arr
							}
						})
					}
				})
			},
			getmygptClassify() {
				this.classifySelected = "mygpt" 
				this.$http("write.mygpt").then(res => {
					if (res.code === 1) {
						let arr = []
						res.data.map(item => {
							const {
								prompt: { id, title, desc, image, isVote,status,openstore,usages,votes,views }
							} = item
							arr.push({ id, title, desc, image, isVote,status,openstore,usages,votes,views })
						})
						this.classifyData.map(item => {
							if (item.id === "mygpt") {
								item.children = arr
							}
						})
					}
				})
			},
			// 收藏
			collectWriteItem(pid, cid, vote) {
				this.$http("write.collect", { prompt_id: cid }).then(res => {
					if (res.code === 1) {
						this.classifyData.map(item => {
							if (item.id == pid) {
								item.children.map(item1 => {
									if (item1.id == cid) {
										item1.isVote = vote == 0 ? 1 : 0
									}
								})
							}
						})
						this.$message.success(res.msg)
					}
				})
			},
			// 进入当前分类
			classifyItem(id) {
				this.$router.push("/write/create?id=" + id)
			},
			// 获取一级分类
			getFirstClassify() {
				this.$http("write.first").then(res => {
					if (res.code === 1 && res.data.length) {
						let arr = []
						res.data.map((item, index) => {
							arr.push({
								id: item.id,
								title: item.title,
								children: []
							})
						})
						this.classifyData = arr
						this.classifySelected = arr[0].id
						this.formclass = res.data
						console.log(arr)
						this.getSecondClassify()
						if (this.token) {
							this.classifyData.unshift({
								id: "collect",
								title: "收藏",
								children: []
							},{
								id: "mygpt",
								title: "我的应用",
								children: []
							})
							this.getCollectClassify()
						}
					}
				})
			},
			// 获取二级分类
			getSecondClassify() {
				if (this.classifySelected === "collect" && this.token) {
					this.getCollectClassify()
					return
				}
				if (this.classifySelected === "mygpt" && this.token) {
					this.getmygptClassify()
					return
				}
				this.$http("write.second", { topic_id: this.classifySelected }).then(res => {
					if (res.code === 1) {
						this.classifyData.map((item, index) => {
							if (item.id == this.classifySelected) {
								item.children = res.data.map(({ id, title, desc, image, isVote,status,openstore,usages,votes,views }) => ({ id, title, desc, image, isVote,status,openstore,usages,votes,views }))
							}
						})
					}
				})
			},
			uploadPictureChange(info) {
			     if (info.file.status === "uploading") {
			         this.upload.loading = true
			         return
			     }
			     if (info.file.response.code != 1) {
			         this.upload.loading = false
			         this.$message.error(info.file.response.msg)
			         return
			     }
			     if (info.file.status === "done") {
			         this.upload.loading = false
					 console.log(info.file.response.data.fullurl)
					 this.imageUrl = info.file.response.data.fullurl
					  // getBase64(info.file.originFileObj, imageUrl => {
					  //          this.imageUrl = imageUrl;
					           this.loading = false;
					         // });
			         // const time = new Date().getTime()
			         // this.local.data.unshift({
			         //     id: "local_" + time,
			         //     image: info.file.response.data.fullurl
			         // })
			     }
			 },
			 // 上传图片之前
			uploadPictureBefore(file) {
			     const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
			     if (!isJpgOrPng) {
			         this.$message.error("仅支持JPG、PNG格式!")
			     }
			     const isLt2M = file.size / 1024 / 1024 < 5
			     if (!isLt2M) {
			         this.$message.error("图片大小超过5MB!")
			     }
			     return isJpgOrPng && isLt2M
			 },
		}
	}
 
</script>
<style scoped>
	.titles1{
		width: 70%;
		margin-left: 15%;
	    text-align: center;
		
	}
	.titles{
		   margin-top: 15px;
		    display: inline-block;
		    vertical-align: top;
		    font-family: PingFangSC-Semibold;
		    font-size: 35px;
		
		    color: #222;
		    letter-spacing: 6px;
		    line-height: 40px;
		    font-weight: 600;
		    -webkit-background-clip: text;
		    background-clip: text;
		    -webkit-text-fill-color: transparent;
		    background-image: linear-gradient(90deg,#4dcecb,#2468f2,#2468f2,#e45d45);
	}
	.avatar-uploader > .ant-upload {
	  width: 128px;
	  height: 128px;
	}
	.ant-upload-select-picture-card i {
	  font-size: 32px;
	  color: #999;
	}
	
	.ant-upload-select-picture-card .ant-upload-text {
	  margin-top: 8px;
	  color: #666;
	}
	.footer_box {
		padding: 12px;
		box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.05);
		border-radius: 8px;
		color: #000;
		cursor: pointer;
		position: relative;
		border: 1px solid transparent;
	
		&:hover {
			border-color: #1890ff;
			box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
		}
	
		.icon {
			width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 15px;
			overflow: hidden;
	
			svg path {
				fill: #1890ff;
			}
	
			img {
				width: 100%;
				height: auto;
			}
		}
	
		h6 {
			color: #000;
		}
	
		p {
			margin-bottom: 0;
			line-height: 20px;
			height: 40px;
		}
	
		
	}
.collect {
			position: absolute;
			top: 18px;
			right: 12px;
		}
.ai-knowledge{
	background: radial-gradient(circle at 12% 55%,rgba(33,150,243,.15),hsla(0,0%,100%,0) 25%),radial-gradient(circle at 85% 33%,rgba(108,99,255,.175),hsla(0,0%,100%,0) 25%);
}
.content_tencent {
	width: 96%;
	margin-left: 2%;
    background: linear-gradient(143deg,#f8f9ff,#fbfbff);
    border: 2px solid #fff;
    border-bottom: none;
    border-radius: 10px;
    /* height: 100%; */
    padding: 24px 18px 24px 24px;
	margin-bottom: 20px;
}
.arco-row-justify-start {
    justify-content: flex-start;
}
.arco-row-align-start {
    align-items: flex-start;
}
.arco-row {
    display: flex;
    flex-flow: row wrap;
}
.arco-col-lg-6 {
    flex: 0 0 25%;
    width: 25%;
}
.arco-col-xs-24 {
    flex: 0 0 100%;
    width: 100%;
}
.arco-col {
    box-sizing: border-box;
}
@media (min-width: 992px)
.arco-col-lg-6 {
    flex: 0 0 25%;
    width: 25%;
}
.arco-col-xs-24 {
    flex: 0 0 100%;
    width: 100%;
}
.arco-col {
    box-sizing: border-box;
}
.mb-2 {
    margin-bottom: .5rem!important;
}
.arco-card-size-medium {
    font-size: 14px;
}
.arco-card-bordered {
    border: 1px solid var(--color-neutral-3);
    border-radius: var(--border-radius-small);
}
.arco-card {
    background: var(--color-bg-2);
    border-radius: var(--border-radius-none);
    position: relative;
    transition: box-shadow .2s linear;
}
.arco-card-size-medium {
    font-size: 14px;
}
.arco-card-bordered {
    border: 1px solid var(--color-neutral-3);
    border-radius: var(--border-radius-small);
}
.arco-card {
    background: var(--color-bg-2);
    border-radius: var(--border-radius-none);
    position: relative;
    transition: box-shadow .2s linear;
}
.rounded-3 {
    border-radius: .3rem!important;
}
.bg-body, .bg-white {
    background-color: #fff!important;
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: #fff;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
}
.arco-card-size-medium .arco-card-body {
    padding: 16px;
}
.arco-card-size-medium .arco-card-body {
    padding: 16px;
}
.arco-card-body {
    color: var(--color-text-2);
}
.arco-card-body {
    color: var(--color-text-2);
}
.justify-between {
    justify-content: space-between;
}
.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.items-center {
    align-items: center;
}


</style>
