

<template>
    <div class="wrap">
        <!-- <div class="top">
			<h1  style="text-align: center;margin-top: 10px;">
			   AI知识库</h1>
				<h5 style="text-align: center;width: 100%;">
					基于大模型进行训练和优化的。通过分析大量的知识库和文本数据，让AI可以提供更贴合企业的知识和信息。</h5>
				<br>
				
        </div> -->
        <div class="main">
            <div class="title">我的知识库</div>
            <div class="content">
                <div class="mian_box create_base" @click.stop="showModal">


                    <div class="main_box_icon">
                        <img src="../assets/imgs/knowledge.png">
                    </div>
                    <div class="main_box_content">
                        <div style="color:#1890ff">创建知识库</div>
                        <div>新建空白知识库，上传数据</div>
                    </div>


                </div>
                <div class="mian_box" v-for="(item,index) in base_list" @click="goDetail(item.id)">
                    <div class="main_box_icon">
                        <img src="../assets/imgs/base.png">
                    </div>
                    <div class="main_box_content">
                        <div><span style="font-size: 15px;font-weight: 600;">{{item.name}}</span></div>
                        <div>文件数:{{item.file_num}}</div>
						<div><span style="font-size: 12px;color:#ccc">{{item.created_at}}</span></div>
                    </div>
                    <div class="delete_base">
                        <a-dropdown>
                            <a class="ant-dropdown-link" @click="goDetail(item.id)" > 管理 <a-icon type="down" /> </a>
                            <a-menu slot="overlay">

                                <a-menu-item>
                                    <a @click="send_edit(item.know_uid)">修改</a>
                                </a-menu-item>
                                <a-menu-item>
                                    <a @click.stop="auth_user(item.id)">授权用户</a>
                                </a-menu-item>
                                <a-menu-item>
                                    <a @click.stop="is_delete(item.id)">删除</a>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                    <a-modal
                        v-model="del_base_box"
                        title="授权给用户使用"
                        @ok="handleOk2"
                        @cancel="handleCancel2"
                        okText="确认"
                        cancelText="取消"
                    >
                        <p style="color: #1890ff">确认后该用户将被允许使用这个知识库</p>
                        <a-input v-model="auth_phone" placeholder="请输入用户手机号" />
						<p style="margin-top: 20px;color: #1890ff;">历史授权记录</p>
						<div style="display: flex;justify-content: left;flex-wrap: wrap;">
						<div v-for="(itemss,indexss) in shouquanlist" class="sqcss">{{itemss.phone}}<a href="#" style="color:red;margin-left: 10px;"  @click="listdeletes(itemss.id)">删除</a></div>
                         </div>
					</a-modal>
                </div>
            </div>
            <a-modal

                title="上传文件"
                :visible="visible"
                :confirm-loading="confirmLoading"
                @ok="handleOk()"
                @cancel="handleCancel"
                okText="确认"
                cancelText="取消"
            >
                <div class="knowledge_form">
                    <div>
                        <div class="form_box">
                            <a-upload-dragger
                                name="file"
                                :multiple="false"
                                action="/addons/chatgpt/xfmlb/basefile_upload2"
                                :headers="headers"
                                accept=".pdf,.docx,.txt"
                                @change="handleChange2"
                                :beforeUpload="beforeUpload"
                            >
                                <p class="ant-upload-drag-icon">
                                    <a-icon type="inbox" />
                                </p>
                                <p class="ant-upload-text">
                                    请先上传单个文档构建知识库
                                </p>
                                <p class="ant-upload-hint">
                                    支持pdf,docx,txt等格式的文档，单文件不超过20MB/100万字
                                </p>
                            </a-upload-dragger>
                        </div>
                        <div class="form_box">
                            <div>知识库名称</div>
                            <a-input v-model="k_name" placeholder="请输入知识库名称" />
                        </div>
						<span style="color:red;">*提示：使用AI知识库功能需<a href="/#/recharge?tabsId=2" target="_blank">开通企业会员</a></span>
                    </div>
                </div>
            </a-modal>
            <div class="title">我被授权的知识库</div>
            <div class="content">
                <div class="mian_box auth_box" v-for="(item,index) in auth_base_list" @click="goDetail(item.id)">
                    <div class="main_box_icon">
                        <img src="../assets/imgs/base.png">
                    </div>
                    <div class="main_box_content">
                        <div><span style="font-size: 15px;font-weight: 600;">{{item.name}}</span></div>
						<div>文件数:{{item.file_num}}<span style="background-color: azure;border-radius: 25px;padding: 5px 15px;color: #1890ff;margin-left: 60px;">使用</span></div>
                        <div><span style="font-size: 12px;color:#ccc">{{item.created_at}}</span> </div>
                    </div>
                </div>
				
            </div>
			<div v-if="auth_base_list.length<1">
			<a-empty description="暂无数据" />
			</div>
        </div>
    </div>

  
</template>

<script setup>

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";


export default {

		data() {
			return {
          headers: {
              authorization: 'authorization-text',
              Token:'',
          },
		  shouquanlist:[],
          visible: false,
          confirmLoading: false,
          del_base_box:false,
          k_name:'',
          base_id:0,
          base_list:[],
          auth_base_list:[],
          auth_phone:'',
          auth_base_id:''
			}
		},
		created() {
        this.getBase();
        this.getuserBase();
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"]),
			...mapState("app", ["sign"])
		},
		methods: {
			...mapActions("user", ["getUserInfo", "getStatisticInfo"]),
        goDetail(id){
            this.$router.push({ name: 'KnowledgeDetail', params: { id } });
        },
		// 删除授权
		listdeletes(id){
			this.$http("role.listdeletes",{id:id}).then(res => {
			    if(res.code == 1){
					 this.$message.success("删除授权成功")
			        this.shouquanlist = res.data;
			    }
					
			})
		},
        handleChange2(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
				this.base_id = this.base_id + ',' + info.file.response.data;
                if(info.file.response.code == 1){
                    this.$message.success(`${info.file.name} 文件上传成功`);
                }else{
                    this.$message.error(info.file.response.msg);
                }
            } else if (info.file.status === 'error') {
                this.$message.error(`${info.file.name} 文件上传失败`);
            }
        },
        beforeUpload(file,fileList) {// 上传前的限制 大小 类型
            this.headers.Token = this.token;
            // console.log(this.token);
            const limitSize = file.size / 1024 / 1024 < 20;
            if (!limitSize) {
                this.$message.error(file.name + "文件大小不能超过20M")
                return false;
            }
        },
        showModal() {
            this.visible = true;
        },
        is_delete(id){
            console.log(this);
            const _this = this;
            this.$confirm({
                title: '删除知识库',
                content: '是否确认删除',
                okText: '确认',
                cancelText: '取消',
                onOk() {
                    _this.$http("role.del_base",{id:id}).then(res => {
                        if(res.code == 1){
                            _this.base_list = res.data;
							 this.$message.success(`知识库删除成功`);
                        }
                    })
                },
                onCancel() {

                },
            });

        },
        auth_user(id) {
            this.del_base_box = true;
            this.auth_base_id = id;
			this.getshouquanlist(id);
        },
        handleOk() {
            this.confirmLoading = true;
            this.$http("role.create_base",{name:this.k_name,file_id:this.base_id}).then(res => {
                if(res.code == 1){
                    this.visible = false;
                    this.confirmLoading = false;
                    location.reload()
                }
            })
        },
        handleCancel(e) {
            this.visible = false;
        },
        handleOk2() {
            this.del_base_box = false;
            this.$http("role.auth_user",{id:this.auth_base_id,phone:this.auth_phone}).then(res => {
                if(res.code == 1){
					this.getshouquanlist(this.auth_base_id);
                    this.$message.success(res.msg);
                }
            })
        },
        handleCancel2(e) {
            this.visible = false;
        },
        getBase(){
            this.$http("role.get_base").then(res => {
                // console.log(res);
                if(res.code == 1){
                    this.base_list = res.data;

                }

            })
        },
		getshouquanlist(id){
		    this.$http("role.get_shouquanlist",{id:id}).then(res => {
		        // console.log(res);
		        if(res.code == 1){
		            this.shouquanlist = res.data;
		
		        }
		
		    })
		},
        getuserBase(){
            this.$http("role.get_base_user").then(res => {
                // console.log(res);
                if(res.code == 1){
                    this.auth_base_list = res.data;

                }

            })
        }
			
		}
	}
</script>
<style scoped>
.sqcss{
	border: 1px solid #1890ff;
	border-radius: 5px;
	padding: 3px 5px;
	width: 24%;
	margin-top: 10px;
	text-align: center;
	margin-left: 1%;
}
.wrap{
	overflow-y: auto;
	height: 84vh;
    width: 100%;
    background: radial-gradient(circle at 12% 55%,rgba(33,150,243,.15),hsla(0,0%,100%,0) 25%),radial-gradient(circle at 85% 33%,rgba(108,99,255,.175),hsla(0,0%,100%,0) 25%);
}
.wrap .top div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrap .top div:nth-child(1){
    font-size: 2vw;
}
.wrap .top div:nth-child(2){
    font-size: 1.2vw;
}
.wrap .main{
    background: linear-gradient(143deg,#f8f9ff,#fbfbff);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 2vw;
}
::v-deep .ant-modal{
    width: 800px !important;
}
.wrap .main .title{
    width: 95%;
    font-size: 1.2vw;
    margin-top: 20px;
}
.wrap .main .content{
    width: 96%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
	flex-wrap: wrap;
	
}
.wrap .main .content .create_base{
    justify-content: flex-start !important;
}
.wrap .main .content .mian_box{
    width: 23%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px;
    margin: 10px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    cursor: pointer;
}
.wrap .main .content .auth_box{
    justify-content: flex-start !important;
}
.wrap .main .content .mian_box .delete_base{
    width: 24%;
    display: flex;
    justify-content: center;
}
.wrap .main .content .mian_box .main_box_icon{
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrap .main .content .mian_box .main_box_icon img{
    width: 70%;
}

.form_box{
    margin-bottom: 15px;
}
</style>
