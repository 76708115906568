<template>
	<!-- 首页 经典案例 -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: '24px' }">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h6>AIGC从入门到精通进阶教程</h6>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<a-radio-group v-model="projectHeaderBtns" size="small">
						<a-radio-button value="all">全部</a-radio-button>
						<a-radio-button value="other">其他</a-radio-button>
					</a-radio-group>
				</a-col>
			</a-row>
		</template>
		<div class="body_box">
			<div class="list_box">
				<div class="item" v-for="(item, index) in caseList" :key="'case_' + item.id">
					<a :href="'/#/news?newsid='+item.id">
					<img  :src='item.image' style="width: 120px;height: 120px;float:left;position: relative;margin-right: 10px;border-radius: 50%;"/>
					<h6 class="c-line-1">{{ item.title }}</h6>
					<p class="c-line-1">{{ item.description }}</p>
					<div class="info">
						<div class="left">
							<a-avatar size="small" :src="item.user_avatar" />
							<span>{{ item.user_name }}</span>
						</div>
						<div class="right">
							<svg viewBox="64 64 896 896" data-icon="like" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 0 0-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 0 0 471 99.9c-52 0-98 35-111.8 85.1l-85.9 311H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h601.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM184 852V568h81v284h-81zm636.4-353l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 22.4-13.2 42.6-33.6 51.8H329V564.8l99.5-360.5a44.1 44.1 0 0 1 42.2-32.3c7.6 0 15.1 2.2 21.1 6.7 9.9 7.4 15.2 18.6 14.6 30.5l-9.6 198.4h314.4C829 418.5 840 436.9 840 456c0 16.5-7.2 32.1-19.6 43z"></path></svg>
							<span style="margin-left: 5px;">{{ item.views }}</span></div>
					</div>
					</a>
				</div>
			</div>
		</div>
	</a-card>
</template>

<script>
	export default {
		props: {},
		data() {
			return {
				projectHeaderBtns: "all",
				caseList: [],
				casefen:[]
			}
		},
		created() {
			this.getCaseList()
			this.getCaseListfen()
		},
		methods: {
			getCaseList() {
				this.$http("case.list", {
					page: 1,
					pagesize: 40
				}).then(res => {
					if (res.code === 1) {
						this.caseList = res.data
					}
				})
			},
			getCaseListfen() {
				this.$http("case.classify", {
					page: 1,
					pagesize: 40
				}).then(res => {
					if (res.code === 1) {
						this.casefen = res.data
					}
				})
			}
			
		}
	}
</script>

<style lang="scss" scoped>
	.body_box {
		.list_box {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			.item {
				width: 49%;
				background: #fafafa;
				border: 1px solid #f5f5f5;
				border-radius: 12px;
				padding: 12px;
				margin-bottom: 12px;

				&:last-child {
					margin-bottom: 0;
				}

				p {
					color: #8c8c8c;
				}

				.info {
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: #8c8c8c;
					margin-top: 30px;
					.left {
						color: #141414;
						display: flex;
						align-items: center;

						span {
							margin-left: 8px;
						}
					}

					.right {
					}
				}
			}
		}
	}
</style>
