<template>
	<!-- 首页 底部左侧信息卡片 -->
	<a-card :bordered="false" class="card-info">
		<a-row type="flex">
			<a-col class="col-content" :span="24" :xl="12">
				<div class="card-content">
					<h5>{{casedata.title}}</h5>
					<div class="neirong" style="width: 95%;" v-html="casedata.content"></div>
				</div>
			</a-col>
		
		</a-row>
	</a-card>
</template>
<style scoped>
	.neirong img {
	  max-width: 20%; /* 限制图片的宽度不超过div的宽度 */
	  text-align: center;
	}
	.neirong table {
	  max-width: 80%; /* 限制图片的宽度不超过div的宽度 */
	  text-align: center;
	  border:1px solid #000;
	}
	.neirong thead {
	  border:1px solid #000;
	}
	.neirong td {
	  border:1px solid #000;
	}
	.neirong th {
	  border:1px solid #000;
	}
	.neirong tr {
	  border:1px solid #000;
	}
</style>
<script>
	
    import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		data() {
			return {
				casedata: [],
				newsid:{}
			}
		},
		
	
		created() {
			this.newsid = this.$route.query
			this.getCaseList()
		},
		mounted(){
			console.log(this.newsid);
		},
		methods: {
			getCaseList() {
				this.$http("case.detail", {
					id: this.newsid.newsid,
				}).then(res => {
					if (res.code === 1) {
						this.casedata = res.data
					}
				})
			}
		}
	}
</script>
